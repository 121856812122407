export const configcode = {
  debug: false, //enable console.log

  // ------------- Staging Settings ----------------
  api: 'stg',
  //base saijai pos api url
  baseUrl: 'https://pos-stg.saijai-crm.com',

  //base saijai api blob url
  basePublic: 'https://public-stg.saijai-crm.com',

  //base saijai backoffice web url
  baseCrmUrl: 'https://backend-stg.saijai-crm.com',

  //base saijai api blob url
  bzbsBlobUrl: 'https://stgstoragebuzzebees.blob.core.windows.net',
  themeVersion: 3,
  // ------------------ End Settings ---------------------
}
